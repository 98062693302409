import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes';
import * as appTool from '@/utils/appTool.js';
import { authTokenRefresh } from '@/services/login.js'
import { fetchAppType } from '@/services/other/index.js'
import store from '@/store';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

//避免太多无效参数，router.query中只准许添加特定参数, 其他仍然保留在search中,
//datatype 投票链接使用
//atypeid, 资产类型id assetTypeId
const PARAMS = [
  'id', // 目前是 AI-创建项目用
  'aid',
  'dataid',
  'from',
  'chatid',
  'npath',
  'tab',
  'tabType',
  'preTime',
  'opinionId',
  'oldTime',
  'voteid',
  'datatype',
  'businessCode',
  'userId',
  'unionId',
  'paid',
  'tenantId',
  'objectId','app','shareid','shareType','shareRelateId',
  'timeStamp',
  'routeViewType', // 视图类型，sjsj 数据收集消息卡片跳转数据收集台账用 / weekReport 投前、投后跳转周报台账
  'datacollectTabId', // 数据收集视图tab选项卡 选中对应资产类型
  'pid', // 数据收集消息卡片跳转数据收集
  'weekReportId', // 周报 id
  'operation', // 跳转写周报
  'configid', // 线索收集卡片跳转
  'assetTypeId', // 线索收集卡片跳转
  'cluesResultId', // 线索收集卡片跳转
  'businessType', // 财报解析-中转页需要
  'createTime', // 创建时间
  'name', //AI机器人解析的公司全称
  'aiFunction', // AI机器人跳转详情页打开ai解析侧边栏
  'openSide', // AI机器人跳转详情页打开ai解析侧边栏 1 打开  没有或者0 不打开
  'openId',
  'activeViewId', //工作台导入跳转
  'appCode', //工作台导入跳转
  'module',//投资于分享导入
];

router.beforeEach(async (to, from)=>{
  // console.log(from);
  let newRoute;
  let queryObj;
  //先转换search字符串，添加到query中
  if(location.search && to.query.nquery != 1){  //如果还没转换过search( nquery==1表示转换过 )
    newRoute = {
      replace: true,
      query:{ ...to.query, nquery: 1 }
    }
    
    queryObj = location.search.slice(1).split('&');
    queryObj = queryObj.reduce((pre, cur)=>{ 
      let val = cur.split('=')[1];
      if(val){
        let key = cur.split('=')[0];
        pre[key] = val
      }
      return pre;
    },{})
    //如果类型是h5，即打开h5的页面
    if((queryObj.ntype == 'h5') && (location.pathname.indexOf('h5') == -1)){
      location.href = location.href.replace(location.origin, location.origin + '/h5');
      return false;
    }
    
    if (queryObj.npath) {
      if(['addvotenoauth','addvoteurl'].includes(queryObj.npath)){
        newRoute.path = `/` + queryObj.npath  
      }else{
        newRoute.path = `/${appTool.getAppName()}/` + queryObj.npath
      }
    } else {
      newRoute.path = to.path
    }
    //只准许添加特定参数
    PARAMS.forEach((ii, index)=>{
      if (queryObj[ii]) {
        newRoute.query[ii] = queryObj[ii]
      }
    })
  }
  // console.log(111, newRoute);
  //哪些路径path需要判断登录
  if (
    [
      'before',
      'after',
      'fund',
      'fundanalyze',
      'datacollect',
      'manage',
      'setnotify',
      'cloud',
      'helper',
      'getmessage',
      'aiTransfer',
      'addtodo',
      'lpinvestor',
      'publicSentiment',
      'setproject',
      'addvote',
      'appIntroduce',
      'cCapital', // c_capital定制
      'shareDetails',
      'test',
      'editTemplate',
      'AICreateProject',
      'AISelectProject'
    ].includes(to.name)
  ) {
    // 投票不需要权限验证
    if(['addvotenoauth','addvoteurl'].includes(queryObj?.npath)){
      return newRoute || true
    }
    // 获取hash的参数
    let queryMatch = location.hash.match(/([a-zA-Z_-]*)=([0-9a-zA-Z*_-]*)/gi)
    queryMatch =
      queryMatch.length < 1
        ? {}
        : queryMatch.reduce((pre, cur) => {
            let val = cur.split('=')[1]
            if (val) {
              let key = cur.split('=')[0]
              pre[key] = val
            }
            return pre
          }, {})

    //如果有token，且没过期，继续下一步，否则跳转登录
    if (appTool.getToken() && !['undefined', 'null', undefined, null].includes(appTool.getToken()) && !appTool.tokenExpire()) {
      // // 解决串token，静默登录(暂时只解决了飞书壳子里的，浏览器中还是老逻辑)
      // if(!appTool.isInFeishu() && !to.query.needLogin) {
      //   let tempInnerUrl = `${location.origin}/#/silentLogin/${appTool.getAppName()}?` // 用于静默授权
      //   //添加特定参数，hash参数补充到search上
      //   PARAMS.forEach((ii, index) => {
      //     if (queryMatch[ii]) {
      //       tempInnerUrl += `${index > 0 ? '&' : ''}${ii}=${queryMatch[ii]}`
      //     }
      //   })

      //   let tempUrl = `https://open.feishu.cn/open-apis/authen/v1/authorize?app_id=${
      //     queryMatch.aid
      //   }&redirect_uri=${encodeURIComponent(tempInnerUrl)}`
      //   location.href = tempUrl
      //   store.dispatch('initStoreWhenLogin');
      //   return false
      // }

      store.dispatch('initStoreWhenLogin');
      return newRoute || true
    } else {
      const appType = await fetchAppType(queryMatch.aid) || 2 // 1 自建应用 2 商店应用

      let innerUrl = `${location.origin}/#/login/${appTool.getAppName()}?`
      localStorage.setItem(queryMatch.aid + '_redirect_url', location.href)
      //添加特定参数，hash参数补充到search上
      PARAMS.forEach((ii, index) => {
        if (queryMatch[ii]) {
          innerUrl += `${index > 0 ? '&' : ''}${ii}=${queryMatch[ii]}`
        }
      })
      // let url = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=${
      //   queryMatch.aid
      // }&redirect_uri=${encodeURIComponent(innerUrl)}`

      // 商店应用更换登录链接（获取code）
      // let url = `https://open.feishu.cn/open-apis/authen/v1/authorize?app_id=${
      //   queryMatch.aid
      // }&redirect_uri=${encodeURIComponent(innerUrl)}&scope=contact:user.employee_id:readonly drive:drive docx:document drive:export:readonly`
      let url
      if (appType == 1) { // 自建应用
        url = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=${queryMatch.aid}&redirect_uri=${encodeURIComponent(innerUrl)}`
      } else if (appType == 2) { // 商店应用
        if (['before', 'after', 'fund', 'lpInvestor', 'lpinvestor'].includes(appTool.getAppName())) {
          url = `https://open.feishu.cn/open-apis/authen/v1/authorize?app_id=${
            queryMatch.aid
          }&redirect_uri=${encodeURIComponent(innerUrl)}&scope=docx:document drive:drive task:task:write wiki:wiki task:attachment:write`
        } else if (appTool.getAppName() === 'appIntroduce') { // 商店应用，消息归档有任务时需要
          url = url = `https://open.feishu.cn/open-apis/authen/v1/authorize?app_id=${
            queryMatch.aid
          }&redirect_uri=${encodeURIComponent(innerUrl)}&scope=task:task:write`
        } else {
          url = `https://open.feishu.cn/open-apis/authen/v1/authorize?app_id=${
            queryMatch.aid
          }&redirect_uri=${encodeURIComponent(innerUrl)}&scope=docx:document drive:drive`
          if (appTool.getAppName() === 'manage') {
            url += ' bitable:app'
          }
        }
      }

      if (appTool.getToken() && !['undefined', 'null', undefined, null].includes(appTool.getToken()) && appTool.tokenExpire()) { // 如果token过期
        try {
          // if (location.host == 'mindsite-dev.yuewe.com') {
          //   alert('token过期，将重刷token')
          // }
          // 换取token
          appTool.getToken() && authTokenRefresh().then(res => {
            appTool.removeToken()
            appTool.setToken(res['login-token'])
            // if (location.host == 'mindsite-dev.yuewe.com') {
            //   alert('token过期，已重刷token')
            // }
            return newRoute || true
          }).catch(error => {
            // if (location.host == 'mindsite-dev.yuewe.com') {
            //   alert('token过期，刷token接口报错，将跳转登录页')
            // }
            location.href = url
            return false
          })
        } catch (error) {
          // if (location.host == 'mindsite-dev.yuewe.com') {
          //   alert('token过期，重刷token失败')
          // }
          // console.log(url);
          location.href = url
          return false
        }
      } else {
        location.href = url
        return false
      }
    }
  } else {
    // console.log('newRoute:' + newRoute)
    return newRoute || true
  }
})

router.afterEach((to, from) => {
  store.dispatch('getAppType');
  // store.dispatch('getDynamicAppName')
})

export default router
