import { ajax } from '@/utils/request'

type Option = { label: string; value: string; disabled?: boolean }

export const fetchViewList = ({ code }: Record<string, string>): Promise<any> => {
  return ajax({
    url: `/view/findMyView?code=${code}`,
    method: 'get',
  })
  // return [
  //   { id: '1', viewLabel: '视图1' },
  //   { id: '2', viewLabel: '视图2' },
  //   { id: '3', viewLabel: '视图 3' },
  //   { id: '4', viewLabel: '视图4' },
  // ]
}
// 多资产视图
export const fetchViewTree = ({ code }: Record<string, string>): Promise<any> => {
  return ajax({
    url: `/view/findAssetSimpleView?code=${code}`,
    method: 'get',
  })
}

// 获取视图详情
export const fetchViewById = ({ id }: Record<string, string>): Promise<any> => {
  return ajax({
    url: `/view/findById?id=${id}`,
    method: 'get',
  })
}

export const fetchSubTableViewById = (data: any) => {
  return ajax({
    url: `/businessView/queryBusinessViewDetail`,
    method: 'post',
    data
  })
}

export const fetchFundList = async () => {
  const res = await ajax({
    method: 'get',
    url: '/fund/queryFundList',
  })

  return (res || []).map((item: { fundName: string; id: string; currencyUnit: string }) => {
    return {
      label: item.fundName,
      value: item.id,
      currency: item.currencyUnit,
    }
  }) as Option[]
}

export const createView = (data: unknown): Promise<string> => {
  return ajax({
    url: '/view/create',
    method: 'post',
    data,
  })
}

export const copyView = (id: string, code: string, newType?: string) => {
  return ajax({
    url: '/view/copy',
    method: 'post',
    data: {
      id,
      code,
      newType,
    },
  })
}

export const updateView = (data: unknown) => {
  return ajax({
    url: '/view/update',
    method: 'post',
    data,
  })
}

export const deleteView = (id: string | number, code: string) => {
  return ajax({
    url: '/view/delete',
    method: 'post',
    params: {
      id,
      code,
    },
  })
}

export const updateViewOrder = (viewIds: string[]) => {
  return ajax({
    url: '/view/changeOrder',
    method: 'post',
    data: viewIds,
  })
}

export const updateTargetViewOrder = (data: Record<string, any>) => {
  return ajax({
    url: '/view/changeOrder',
    method: 'post',
    data,
  })
}

export const fetchStaffList = (name: string = '') => {
  return ajax({
    url: '/user/findUsers',
    method: 'post',
    data: {
      name,
    },
  })
}

// 列表人员筛选需要展示所有人（包括离职人员）
export const fetchFindAllUser = (name: string = '') => {
  return ajax({
    url: '/user/findAllUser',
    method: 'post',
    data: {
      name,
    },
  })
}

export const fetchCategoryTree = () => {
  return ajax({
    url: '/selectOption/findAreaTree',
    method: 'post',
    data: {
      type: 'category',
    },
  })
}

export const fetchAreaTree = () => {
  return ajax({
    url: '/selectOption/findAreaTree',
    method: 'post',
    data: {
      type: 'country',
    },
  })
}

export const fetchTransactionList = () => {
  return ajax({
    url: '/insight/trade/list',
    method: 'post',
    data: {
      from: 0,
      size: 5,
    },
  })
}

export async function fetchCompany(keyword: string = '') {
  return ajax({
    method: 'get',
    url: '/insight/company/search',
    params: {
      keyword,
    },
  })
}

export const fetchProjectByKeyword = (keyword: string = '', businessCode = 'beforeProject') => {
  return ajax({
    url: `/${businessCode}/queryInfoByName?projectName=${keyword ?? ''}`,
    method: 'get',
  })
}

export const fetchProject = (
  keyword: string = '',
  viewId: string = '',
  code: string = 'beforeProject',
  isFund: boolean = false,
) => {
  const filters = keyword.trim()
    ? isFund
      ? [
          {
            operator: 'LIKE',
            fieldName: 'fundName',
            columnName: 'fund_name',
            fieldValues: [keyword],
            businessCode: code,
          },
          {
            operator: 'LIKE',
            fieldName: 'fundFullName',
            columnName: 'fund_full_name',
            fieldValues: [keyword],
            businessCode: code,
          },
          {
            operator: 'LIKE',
            fieldName: 'fundNum',
            columnName: 'fund_num',
            fieldValues: [keyword],
            businessCode: code,
          },
        ]
      : [
          {
            operator: 'LIKE',
            fieldName: 'projectName',
            columnName: 'project_name',
            fieldValues: [keyword],
            businessCode: code,
          },
          {
            operator: 'LIKE',
            fieldName: 'companyFullName',
            columnName: 'company_full_name',
            fieldValues: [keyword],
            businessCode: code,
          },
        ]
    : undefined

  return ajax({
    url: `/${code}/queryList`,
    method: 'post',
    data: {
      viewId,
      filters,
    },
  })
}

export const getCloudUrl = () => {
  return ajax({
    url: '/insight/getCloudDealUrl',
    method: 'get',
  })
}

export const fetchInvestorList = (keyword: string = '') => {
  return ajax({
    url: '/lpInvestor/queryInvestorListByName',
    method: 'post',
    data: {
      name: keyword,
    },
  })
}

export const fetchNewestInvestList = (data: {
  investId?: string
  pageSize: number
  currentPage: number
  judgeFund?: number // 0: 最新投资, 1: 基金
  type?: number
}) => {
  const url = '/publicInfo/company/invest'

  return ajax({
    url,
    method: 'post',
    data,
  })
}

export const updateViewShowType = (data: {
  id: string,
  viewType: 'list' | 'board',
}) => {
  return ajax({
    url: '/view/changeViewType',
    method: 'post',
    data,
  })
}

export const updateViewType = (data: {
  id: string
  type: string
}) => {
  return ajax({
    url: '/view/changeType',
    method: 'post',
    data,
  })
}
// 查看关键指标是否资产类型有任一启用
export const queryTabIsOpen = () => {
  return ajax({
    url: `/layoutTab/queryTabIsOpen`,
    method: 'get',
  })
}

export const transactionPeQueryRound = (code: string) => {
  return ajax({
    url: `/transactionPe/queryRound?businessCode=${code}`,
    method: 'get',
  })
}

// 人员接口（台账人员筛选 现在应该是包含了离职  可以优化一下 如果这个人有数据 就能筛出来  如果没有数据就过滤）
// type: 1、台账数据 2、周报  3 子表 4 跟进记录 5 投票
export const userFindUsedUser = (data: any) => {
  return ajax({
    url: '/user/findUsedUser',
    method: 'post',
    data,
  })
}
// 获取现在的投资云用户
export const userFindActiveUser = (data: any) => {
  return ajax({
    url: '/user/findActive',
    method: 'post',
    data,
  })
}


// 获取持有情况标签的字段值

export const fetchTotalTags = () => {
  return ajax({
    url: `/field/queryTagField`,
    method: 'get',
  })
}

// 台账移除成员 - 所选成员在所选项目中的身份
export const checkMembersSelectRights = (data: any) => {
  return ajax({
    url: '/datarights/owner/check',
    method: 'post',
    data,
  })
}
// 批量移除成员

export const batchRemoveMembers = (data: any) => {
  return ajax({
    url: '/datarights/batchRemoveMember',
    method: 'post',
    data,
  })
}

