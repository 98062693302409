<template>
  <div
    :class="['task-page-index', props.from == 'message' ? 'message-task-page' : '']" @dragover="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop">
    <!-- 拖拽 UI -->
   <div  v-if="state.isDragover" class="record-drag-upload-mask" >
       <div class="tips-content">
         <img src="@/assets/img/detailsSide/dragIng.svg" alt="">
         <span>拖拽文件至此即可上传</span>
       </div>
     </div>
    <div class="content">
      <a-textarea
        max-length="750"
        ref="textareaRef"
        class="textarea"
        v-model="state.info.summary"
        placeholder="输入标题，回车确认"
        allow-clear
        auto-size
        v-on:keyup.enter="handlePressEnter"
      />
      <!-- @blur="handlerBlur" -->
      <ul>
        <li>
          <a-tooltip content="负责人">
            <i class="iconfont icon-icon_mb2" />
          </a-tooltip>
          <addPerson
            placeholder="添加负责人"
            position="bl"
            :persons="state.info.assigneePersons"
            @getSelectPerson="getSelectPerson"
          >
            <a-tooltip
              v-if="state.info.assignee?.length"
              :content="assigneeName(state.info.assignee)"
            >
              <a-button type="text" class="avatar-cont-wrap">
                <template v-for="(ii, index) in state.info.assignee">
                  <div v-if="index < 5" class="avatar-cont">
                    <img class="avatar-img" :src="ii.avatar" width="32" alt="" />
                  </div>
                </template>
                <span>
                  {{
                    state.info.assignee.length > 1
                      ? state.info.assignee.length + ' 人'
                      : state.info.assignee[0].name
                  }}
                </span>
              </a-button>
            </a-tooltip>
            <a-button v-else type="text" style="color: #86909c; padding: 0 4px; height: 28px"
              >添加负责人</a-button
            >
          </addPerson>
        </li>
        <li>
          <a-tooltip content="截止时间">
            <i class="iconfont icon-icon_time_other" />
          </a-tooltip>
          <div class="time-wrap">
            <template v-if="!state.info.due">
              <a-button @click="handlerCheckDate(0)">
                <!-- <i class="iconfont icon-icon_time_today">
                <span>动态</span> -->
                <img
                  :src="`/static/img/feishuDayIcon/${dayjs().date()}.svg`"
                  alt=""
                  style="margin-right: 4px"
                />
                <!-- </i> -->
                今天
              </a-button>
              <a-button @click="handlerCheckDate(1)">
                <i class="iconfont icon-icon_time_tom" style="color: #2dbeab" /> 明天
              </a-button>
            </template>

            <a-trigger
              :popup-translate="[0, 0]"
              trigger="click"
              class="task-date-trigger"
              @popup-visible-change="handlerDateVisibleChange"
            >
              <div v-if="state.info.due" class="currenValueButton">
                <a-button
                  v-if="state.info.due == dayjs().add(-1, 'day').format('YYYY-MM-DD')"
                  style="color: red"
                  >昨天 {{ getTimeText }} 截止
                  <a-tooltip v-if="state.info.reminders !== undefined" :content="getTipsText"
                    ><i class="iconfont icon-icon_bell" />
                  </a-tooltip>
                </a-button>
                <a-button
                  v-else-if="state.info.due == dayjs().add(0, 'day').format('YYYY-MM-DD')"
                  :style="{
                    color:
                      dayjs(state.info.due).add(state.info.dueTime || 0, 'minute') < dayjs()
                        ? state.info?.checkbox
                          ? 'red'
                          : '#3272fe'
                        : '#3272fe',
                  }"
                >
                  今天 {{ getTimeText }} 截止
                  <a-tooltip v-if="state.info.reminders !== undefined" :content="getTipsText"
                    ><i class="iconfont icon-icon_bell"
                  /></a-tooltip>
                </a-button>
                <a-button v-else-if="state.info.due == dayjs().add(1, 'day').format('YYYY-MM-DD')"
                  >明天 {{ getTimeText }} 截止
                  <a-tooltip v-if="state.info.reminders !== undefined" :content="getTipsText"
                    ><i class="iconfont icon-icon_bell"
                  /></a-tooltip>
                </a-button>
                <a-button
                  v-else-if="state.info.due < dayjs().add(1, 'day').format('YYYY-MM-DD')"
                  style="color: red"
                >
                  <span>
                    {{
                      dayjs(state.info.due).year() == dayjs().year()
                        ? dayjs(state.info.due).format('M月D日')
                        : dayjs(state.info.due).format('YYYY年M月D日')
                    }}
                    {{ getTimeText }} 截止</span
                  >
                  <a-tooltip v-if="state.info.reminders !== undefined" :content="getTipsText"
                    ><i class="iconfont icon-icon_bell"
                  /></a-tooltip>
                </a-button>
                <a-button v-else-if="state.info.due > dayjs().add(1, 'day').format('YYYY-MM-DD')">
                  <span>
                    {{
                      dayjs(state.info.due).year() == dayjs().year()
                        ? dayjs(state.info.due).format('M月D日')
                        : dayjs(state.info.due).format('YYYY年M月D日')
                    }}
                    {{ getTimeText }} 截止</span
                  >
                  <a-tooltip v-if="state.info.reminders !== undefined" :content="getTipsText"
                    ><i class="iconfont icon-icon_bell"
                  /></a-tooltip>
                </a-button>
                <i class="iconfont icon-icon_close_med" @click.stop="clearTime" />
              </div>
              <a-button v-else>
                <i class="iconfont icon-icon_time_other" style="color: #646a73" />其他时间
              </a-button>
              <template #content>
                <!-- :default-value="state.pickerInfo.pickerValue" -->
                <a-date-picker
                  v-model="state.pickerInfo.pickerValue"
                  hide-trigger
                  class="task-date-picker"
                  style="width: 280px"
                  :show-now-btn="false"
                  @change="pickerChanage"
                >
                  <template #extra>
                    <div class="picker-footer">
                      <div class="time-checked">
                        <div class="input-wrap">
                          <a-input
                            v-model="state.pickerInfo.due"
                            class="input"
                            placeholder="截止日期"
                            @blur="dateBlur"
                          >
                            <template v-if="state.pickerInfo.due?.length > 0" #suffix>
                              <i class="iconfont icon-icon_empty" @click="clearCheckTime" />
                            </template>
                          </a-input>
                          <template v-if="state.pickerInfo.checkbox">
                            <a-divider direction="vertical" />
                            <a-select
                              popup-container=".task-date-trigger"
                              v-model="state.pickerInfo.dueTime"
                              :style="{ width: '160px' }"
                            >
                              <a-option
                                v-for="(option, index) in getTimeOptions"
                                :key="index"
                                :value="option.value"
                              >
                                <span> {{ option.label }}</span>
                                <i class="iconfont icon-close-med" />
                              </a-option>
                            </a-select>
                          </template>
                        </div>
                        <a-checkbox
                          class="checkbox"
                          v-model="state.pickerInfo.checkbox"
                          @change="checkboxChange"
                          >具体时间</a-checkbox
                        >
                      </div>
                      <div class="deadline-wrap">
                        <i
                          class="iconfont icon-icon_bell"
                          style="color: #646a73; margin-right: 4px"
                        />
                        <div class="deadline-selectWrap">
                          <a-select
                            popup-container=".task-date-trigger"
                            placeholder="到期提醒"
                            v-model="state.pickerInfo.reminders"
                            :style="{ width: '160px' }"
                            @change="remindersChange"
                          >
                            <template #arrow-icon>
                              <i class="iconfont icon-icon_d_arrow" />
                            </template>
                            <a-option
                              v-for="(option, index) in state.pickerInfo.checkbox
                                ? deadlineOptions1
                                : deadlineOptions2"
                              :key="index"
                              :value="option.value"
                              :disabled="option.value == -1080"
                            >
                              <span> {{ option.label }}</span>
                              <i class="iconfont icon-close-med" />
                            </a-option>
                          </a-select>
                          <i
                            class="iconfont icon-icon_close_med"
                            @click="state.pickerInfo.reminders = undefined"
                          />
                        </div>
                      </div>
                      <div class="clearAll">
                        <a-button type="text" @click="clearTime">全部清除</a-button>
                      </div>
                    </div>
                  </template>
                </a-date-picker>
              </template>
            </a-trigger>
          </div>
        </li>
        <li
          v-if="state.showFile || state.info.fileList?.length || props.from == 'message'"
          class="file-li"
        >
          <a-tooltip content="附件">
            <i class="iconfont icon-icon_12" />
          </a-tooltip>
          <div class="file-wrap">
            <div v-if="state.info.fileList?.length" class="addFile-tips">
              <span :style="{ color: state.info.fileList?.length > 5 ? 'red' : '' }"
                >{{ state.info.fileList?.length }}/5个</span
              >
              附件<a-divider
                style="height: 12px; margin: 0 6px"
                direction="vertical"
              />单个文件最大为 50 MB 
            </div>
            <ul v-if="state.info.fileList?.length" class="task-cus-uploaded-file-list">
              <li v-for="(file, idx) in state.info.fileList" :key="idx">
                <div class="edit-file-item">
                  <!-- @click="handlePreview(file)" -->
                  <div class="file-item-left">
                    <fileIcon class="item-name-file-type" :file="file" />
                    <span
                      class="file-name"
                      :style="{ color: file.file?.size > fileMaxSize ? 'red' : '' }"
                    >
                      {{ file.name || file.fileName }}
                      <template v-if="file.file?.size > fileMaxSize">
                        <a-divider style="height: 12px; margin: 0 6px" direction="vertical" />
                        {{ (file.file.size / (1024 * 1024)).toFixed(2) + 'MB' }}
                      </template>
                    </span>
                  </div>
                  <span class="icon-cont">
                    <i
                      class="iconfont icon-icon_delete icon-cus-color"
                      @click="delLocalFile(state.info.fileList, idx)"
                    />
                  </span>
                </div>
              </li>
            </ul>
            <!-- :limit="5" -->
            <!-- @before-upload="beforeUpload" -->
            <a-upload
              ref="uploadref"
              :auto-upload="false"
              @change="uploadOnChange"
              :default-file-list="state.defaultFilelist"
              v-model:file-list="state.info.fileList"
              :show-file-list="false"
              multiple
              showOnExceedLimit
              show-upload-button
            >
              <template #upload-button>
                <a-space>
                  <a-button
                    v-if="!state.info.fileList?.length"
                    ref="addFileButton"
                    type="text"
                    style="color: #86909c; padding: 0 4px; height: 24px"
                    >添加附件</a-button
                  >
                  <a-button
                    v-else-if="state.info.fileList?.length < 5"
                    type="text"
                    style="color: #1f2329; padding: 0 4px; height: 24px"
                  >
                    <i
                      class="iconfont icon-icon_add"
                      style="margin-right: 6px; color: #646a73"
                    />添加附件
                  </a-button>
                  <!-- <a-button
                    v-else
                    disabled
                    type="text"
                    style="color: #1f2329; padding: 0 4px; height: 28px"
                  >
                    <i
                      class="iconfont icon-icon_add"
                      style="margin-right: 6px; color: #646a73"
                    />添加附件
                  </a-button> -->
                </a-space>
              </template>
            </a-upload>
          </div>
        </li>
        <li v-if="props.from == 'message'" style="margin: 24px 0px 0px 0px">
          <a-tooltip content="负责人">
            <i class="iconfont icon-icon_mb2" />
          </a-tooltip>
          <addPerson
            placeholder="添加关注人"
            position="tl"
            :persons="state.info.followerPersons"
            @getSelectPerson="getSelectFollowerPerson"
          >
            <a-tooltip
              v-if="state.info.follower?.length"
              :content="assigneeName(state.info.follower)"
            >
              <a-button type="text" class="avatar-cont-wrap">
                <template v-for="(ii, index) in state.info.follower">
                  <div v-if="index < 5" class="avatar-cont">
                    <img class="avatar-img" :src="ii.avatar" width="32" alt="" />
                  </div>
                </template>
                <span>
                  {{
                    state.info.follower.length > 1
                      ? state.info.follower.length + ' 人'
                      : state.info.follower[0].name
                  }}
                </span>
              </a-button>
            </a-tooltip>
            <a-button v-else type="text" style="color: #86909c; padding: 0 4px; height: 28px"
              >添加关注人</a-button
            >
          </addPerson>
        </li>
      </ul>
    </div>
    <div v-if="props.from != 'message'" class="footer">
      <div class="left-wrap">
        <template v-if="!state.showFile && state.info.fileList.length==0">
          <a-tooltip content="附件">
            <i class="iconfont icon-icon_12" @click="handleShowFile" />
          </a-tooltip>
          <a-divider style="height: 16px;margin: 0 10px;" direction="vertical" />
        </template>
        <addPerson
          placeholder="添加关注人"
          position="tl"
          :persons="state.info.followerPersons"
          @getSelectPerson="getSelectFollowerPerson"
        >
          <a-tooltip
            v-if="state.info.follower?.length"
            :content="assigneeName(state.info.follower)"
          >
            <a-button type="text" class="avatar-cont-wrap">
              <template v-for="(ii, index) in state.info.follower">
                <div v-if="index < 5" class="avatar-cont">
                  <img class="avatar-img" :src="ii.avatar" width="32" alt="" />
                </div>
              </template>
              <span>
                {{
                  state.info.follower.length > 1
                    ? state.info.follower.length + ' 人'
                    : state.info.follower[0].name
                }}
              </span>
            </a-button>
          </a-tooltip>
          <a-button v-else type="text" class="addMembers"
            ><i class="iconfont icon-icon_focus"></i>添加关注人</a-button
          >
        </addPerson>
        <!-- <a-button class="addMembers" @click="">
          <i class="iconfont icon-icon_focus"></i> 添加关注人
        </a-button> -->
      </div>
      <div class="button-wrap">
        <a-button type="outline" style="width: 80px" @click="emit('cancel')">取消</a-button>
        <a-tooltip :disabled="!disabledCreate" content="请填写任务标题">
          <a-button
            style="width: 80px"
            type="primary"
            @click="create"
            :disabled="disabledCreate"
            >{{ props.editInfo?.summary ? '保存' : '创建' }}</a-button
          >
        </a-tooltip>
      </div>
    </div>
    <div v-else class="footer">
      <a-tooltip :disabled="!disabledCreate" content="请填写任务标题">
        <a-button type="primary" @click="create" :disabled="disabledCreate">{{
          props.editInfo?.summary ? '保存' : '创建'
        }}</a-button>
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import fileIcon from '@/components/fileIcon.vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useRoute } from 'vue-router'
import { computed, onMounted, PropType, reactive, nextTick, ref, watchEffect, toRaw } from 'vue'
import { useStore } from 'vuex'
import { Message } from '@arco-design/web-vue'
import addPerson from '@/views/components/addPerson/index.vue'
import { cloneDeep } from 'lodash'
// import { assigneeName , deadlineOptions1, deadlineOptions2, timeOptions } from '@/utils/task.js'
import {
  deadlineOptions1,
  deadlineOptions2,
  timeOptions,
  getTimeText as timeText,
  getTipsText as tipsText,
  assigneeName,
} from '@/utils/task.js'

/**
 * APIs
 */

/**
 * Components
 */

/**
 * Resources
 */

/**
 * Types
 */
// 接口层面的数据结构
// 任务截止时间
// "due": {
//         "timestamp": "1675454764000",
//         "is_all_day": true
// }
// 相对于截止时间的提醒时间分钟数。例如30表示截止时间前30分钟提醒；0表示截止时提醒。
// "reminders": [
//         {
//             "relative_fire_minute": 30
//         }
//     ]
type State = {
  defaultFilelist: any[]
  info: {
    deleteFileGuid: string[]
    fileList: any[]
    completed: boolean
    checkbox: boolean
    id: string
    summary: string
    due: string
    dueTime: number | undefined
    tipsTime: number
    reminders: number | undefined
    members: any[]
    /**
     * @example负责人
     */
    assignee: any[]
    assigneePersons: any[]
    /**
     * @example关注人
     */
    follower: any[]
    followerPersons: any[]
  }
  pickerInfo: {
    checkbox: boolean
    due: string
    pickerValue: string | number | Date | undefined
    dueTime: number
    reminders: number | undefined
    tipsTime: number
  }
  showFile: boolean
  noAuth: boolean
  dragTime: number | null
  isDragover: boolean
}

/**
 * Constants
 */

const route: any = useRoute()
const fileMaxSize = 50 * 1024 * 1024
/**
 * Props
 */
const props = defineProps({
  from: {
    type: String,
    default: '',
  },
  editInfo: {
    type: Object as PropType<State['info']>,
    default: null,
  },
})

/**
 * Hooks
 */
const emit = defineEmits(['cancel', 'create'])
const store = useStore()
dayjs.extend(customParseFormat)

/**
 * States
 */
const addFileButton = ref()
const uploadref = ref()
const textareaRef = ref()
const state: State = reactive({
  defaultFilelist: [],
  info: {
    deleteFileGuid: [],
    fileList: [],
    completed: false,
    due: '',
    id: '',
    summary: '',
    checkbox: false,
    reminders: undefined,
    dueTime: 1080,
    tipsTime: 0,
    members: [],
    follower: [],
    assignee: [],
    assigneePersons: [],
    followerPersons: [],
  },
  pickerInfo: {
    checkbox: false,
    due: '',
    pickerValue: undefined,
    reminders: undefined,
    dueTime: 1080,
    tipsTime: 0,
  },
  showFile: false,
  noAuth: false,
  // members: [],
  // assignee: [],
  // follower: [],
  dragTime: null,
  isDragover: false,
})

const disabledCreate = computed(() => {
  return state.info.summary.trim().length == 0
})
const getTimeText = computed(() => {
  return timeText(state.info)
  // let text = null
  // if (state.info.checkbox) {
  //   text = timeOptions.find(({ value }) => state.info.dueTime == value)?.label
  // }
  // return text || null
})

const getTipsText = computed(() => {
  return tipsText(state.info)
  // let text = (state.info.checkbox ? deadlineOptions1 : deadlineOptions2).find(
  //   ({ value }) => state.info?.reminders == value,
  // )?.label
  // return text
})
const getTimeOptions = computed(() => {
  // let timeisInOptions= timeOptions.find(({ value }) => 120 == value)
  let timeisInOptions: any = timeOptions.find(({ value }) => state.pickerInfo.dueTime == value)
  if (!timeisInOptions && state.pickerInfo.dueTime) {
    let hour =
      parseInt(state.pickerInfo.dueTime / 60) < 10
        ? '0' + parseInt(state.pickerInfo.dueTime / 60)
        : parseInt(state.pickerInfo.dueTime / 60)
    let minute =
      state.pickerInfo.dueTime % 60 < 10
        ? '0' + (state.pickerInfo.dueTime % 60)
        : '0' + (state.pickerInfo.dueTime % 60)
    let addTime = { value: Number(state.pickerInfo.dueTime), label: `${hour}:${minute}` }
    let options = cloneDeep(timeOptions)
    options.push(addTime)
    options.sort(function (a, b) {
      return a.value - b.value
    })
    return options
  }
  return timeOptions
})

/**
 * Requests
 */

/**
 * Events
 */
const handleShowFile = () => {
  state.showFile = true

  nextTick(() => {
    addFileButton.value.$el.click()
  })
}
const checkboxChange = (v: any) => {
  if (v) {
    if (!state.pickerInfo.due) {
      state.pickerInfo.pickerValue = dayjs().format('YYYY-MM-DD')
    }
    state.pickerInfo.dueTime = 1080

    state.pickerInfo.reminders = 30
  } else {
    // if (state.pickerInfo.date == '') {

    state.pickerInfo.reminders = undefined
    // state.pickerInfo.reminders = 900
    //   state.pickerInfo.pickerValue = dayjs().format('YYYY-MM-DD')
    //   state.pickerInfo.time=1080
    // }
  }
}

// const beforeUpload = (file: File): Promise<boolean | File> => {
// return new Promise((resolve, reject) => {
//   if (file.size > 5 * 1024 * 1024) {
//     Message.error('文件大小不能超过50MB')
//     reject('文件大小不能超过50MB')
//   } else {
// resolve(file)
//   }
// })
// }
// const handlePreview = (file: any) => {
//   if (file.response) {
//     window.open(file.response.data.url)
//   } else if(file.url){
//     window.open(file.url)
//   }
// }
const handleDragEnter = (event) => {
  event.preventDefault()
  if (state.dragTime) {
    clearTimeout(state.dragTime)
    state.dragTime = null
  }
  state.isDragover = true
}
const handleDragLeave = () => {
  state.dragTime = setTimeout(() => {
    state.isDragover = false
    clearTimeout(state.dragTime)
    state.dragTime = null
  }, 100)
}
const handleDrop = (event: any) => {
  state.showFile=true
  handleDragLeave()
  event.preventDefault()
  const files = event.dataTransfer.files
  // files.map((f) => {
  for (let index = 0; index < files.length; index++) {
      const f = files[index];
    state.info.fileList.push({
      file: f,
      name: f.name,
      size: f.size,
      guid: Math.random().toString(36).substr(2, 9),
      preview: URL.createObjectURL(f),
      status: 'done',
    })
  }
  console.log(files)
}
const handlePressEnter = () => {
  state.info.summary=state.info.summary.trim()
  if (state.info?.summary?.length) {
    create()
  }
}
const getSelectFollowerPerson = (person: any) => {
  state.info.follower = person || []
}
const getSelectPerson = (person: any) => {
  state.info.assignee = person || []
}
const uploadOnChange = (fileList: any) => {
  console.log(fileList)
  // let arr=fileList.map((item)=>{
  //   return cloneDeep(item)
  // })
  // let arr:any=[]
  // fileList.map((ff:any)=>{
  //   let delFile= state.info.deleteFileGuid.find((id)=>{
  //     return ff.guid==id
  //   })
  //   if(!delFile){
  //     arr.push(ff)
  //   }
  //  })
  //  state.info.fileList.push(fileItem)
  // state.info.fileList = fileList
  // console.log(state.info.fileList)
}
const delLocalFile = (item: any, idx: number) => {
  if (item[idx]?.guid) {
    state.info.deleteFileGuid.push(item[idx].guid)
  }
  state.info.fileList.splice(idx, 1)

  console.log(state.info.fileList)
}
const remindersChange = () => {
  if (!state.info.due) {
    state.info.due = dayjs().format('YYYY-MM-DD')
    state.pickerInfo.pickerValue = dayjs().format('YYYY-MM-DD')
  }
}

const dateBlur = () => {
  if (!dayjs(state.pickerInfo.due, 'YYYY-MM-DD', true).isValid()) {
    state.pickerInfo.due = state.pickerInfo.pickerValue as string
  }
}
const clearTime = () => {
  state.info.due = ''
  state.pickerInfo.pickerValue = undefined
  state.pickerInfo.checkbox = false
  state.pickerInfo.reminders = undefined
  // state.info.reminders=undefined
}
const clearCheckTime = () => {
  state.pickerInfo.pickerValue = undefined
  state.pickerInfo.checkbox = false
  state.pickerInfo.reminders = undefined
}

const pickerChanage = (date: string) => {
  if (state.pickerInfo.checkbox) {
    state.pickerInfo.reminders = 30
    state.info.reminders = 30
  } else {
    state.pickerInfo.reminders = 900
    state.info.reminders = 900
  }
  // console.log(date, dayjs(date, 'YYYY-MM-DD', true).isValid())
}

const handlerDateVisibleChange = (v: boolean) => {
  if (!v) {
    state.info.due = state.pickerInfo.due
    state.info.dueTime = state.pickerInfo.checkbox ? state.pickerInfo.dueTime : undefined
    state.info.tipsTime = state.pickerInfo.tipsTime
    state.info.reminders = state.pickerInfo.reminders

    state.info.checkbox = state.pickerInfo.checkbox
  }
}
// 今天明天
const handlerCheckDate = (day: number = 0) => {
  state.info.checkbox = false
  state.info.dueTime = undefined
  state.info.reminders = undefined
  state.info.due = dayjs().add(day, 'day').format('YYYY-MM-DD')
  state.pickerInfo.pickerValue = dayjs().add(day, 'day').format('YYYY-MM-DD')

  // state.pickerInfo.reminders = 900
  // state.info.reminders = 900
}
const create = () => {
  state.info.summary=state.info.summary.trim()
  // 第一次创建的时候负责人和关注
  let info = cloneDeep(state.info)
  if (!props.editInfo?.id) {
    info.assignee.map((fItem: any) => {
      if (!info.follower.find((item) => item.id == fItem.id)) {
        info.follower.push(fItem)
      }
    })
    if (!info.follower.find((item) => item.id == store.state.userSelfInfo.id)) {
      info.follower.push(store.state.userSelfInfo)
    }
  }
  if (info.fileList.length > 5) {
    return Message.error('附件超出5个')
  }

  for (var i = 0; i < info.fileList.length; i++) {
    if (info.fileList[i]?.file?.size > fileMaxSize) {
      return Message.error('存在单个文件过大，请处理后重试')
    }
  }

  emit('create', info)
  console.log(state)
}
/**
 * Effects
 */

watchEffect(() => {
  state.pickerInfo.due = state.pickerInfo.pickerValue as string
  // state.pickerInfo.dueTime = state.pickerInfo.dueTime
})

watchEffect(() => {
  if (dayjs(state.pickerInfo.due, 'YYYY-MM-DD', true).isValid()) {
    state.pickerInfo.pickerValue = state.pickerInfo.due as string
  }
})
watchEffect(() => {
  if (store.state.userSelfInfo.id && !props.editInfo?.summary) {
    state.info.assigneePersons = [store.state.userSelfInfo]
    state.info.assignee = [store.state.userSelfInfo]
  }
})
onMounted(async () => {
  if (props.editInfo?.summary) {
    let info = cloneDeep(props.editInfo)
    state.info = { ...state.info, ...info }
    state.pickerInfo.pickerValue = state.info.due
    state.pickerInfo.reminders = state.info.reminders
    if (info.dueTime !== undefined) {
      state.pickerInfo.checkbox = true
      state.pickerInfo.dueTime = info.dueTime
    }
    state.defaultFilelist = info.fileList
    state.info.assignee = state.info.assignee
    state.info.assigneePersons = state.info.assignee
    state.info.follower = state.info.follower
    state.info.followerPersons = state.info.follower
    if (info.fileList.length > 0) {
      state.showFile = true
    }
  }
  textareaRef.value?.focus()
  // else if (store.state.userSelfInfo.id) {
  //   state.info.assigneePersons=[store.state.userSelfInfo]
  //   state.info.assignee=[store.state.userSelfInfo]
  // }
})

/**
 * Lifecycles
 */
// console.log(dayjs().format('YYYY-DD-MM'))
// state.pickerInfo.pickerValue = dayjs().format('YYYY-MM-DD')
// state.info.assigneePersons = [store.state.userSelfInfo.id]
// props.taskInfo
</script>
<style lang="scss">
.task-date-trigger {
  // left: 0 !important;
  // top: 0 !important;
  .arco-picker-container,
  .arco-picker-range-container {
    border: 1px solid #dee0e3;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
  }
  .arco-picker-footer-extra-wrapper {
    padding: 0;
    border: 0;
  }
  .arco-select-dropdown {
    padding: 4px;
    box-sizing: border-box;
    border-radius: 6px;
    .arco-select-dropdown-list-wrapper {
      max-height: 192px;
    }
    .arco-select-option-actice {
      background-color: #eceeee;
      padding: 0 12px;
    }
    .arco-select-option-content {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .arco-select-option {
      width: 100%;
      // background-color: transparent !important;
      height: 32px;
      border-radius: 4px;
      padding: 0 8px;
      box-sizing: border-box;
      .arco-select-option-content {
        width: 100%;
        display: flex;
        align-items: center;
        line-height: 32px;
        span {
          flex: 1;
        }
      }
      .icon-close-med {
        display: none;
        font-size: 18px;
        margin-left: auto;
        // color: #3272FE;
      }
      &.arco-select-option-selected {
        color: #3272fe !important;
        .icon-close-med {
          display: block !important;
        }
        .right-cont {
          width: calc(100% - 64px) !important;
        }
      }
    }
  }
  .task-date-picker {
    .arco-picker-body .arco-picker-row .arco-picker-cell .arco-picker-date {
      padding: 0;
      .arco-picker-date-value {
        &:hover {
          background-color: #e1e9ff;
        }
        width: 32px;
        height: 32px;
        line-height: 30px;
      }
    }
    .arco-picker-cell-selected {
      .arco-picker-date {
        .arco-picker-date-value {
          background: #3272fe !important;
          border-radius: 50%;
          // background-color: transparent;
        }
      }
    }
    .arco-picker-cell-today {
      .arco-picker-date {
        .arco-picker-date-value {
          border: 1px solid #3272fe;
          box-sizing: border-box;
          border-radius: 50%;
        }
      }

      &::after {
        display: none;
      }
    }
    .picker-footer {
      .time-checked {
        padding: 0 16px 16px 16px;
        .input-wrap {
          display: flex;
          border: 1px solid #dee0e3;
          border-radius: 6px;
          overflow: hidden;
          align-items: center;
          &:hover {
            border: 1px solid #3272fe;
            .icon-icon_empty {
              display: block;
            }
          }
          .input {
            height: 32px;
            border: 0;
            .arco-input{
             
            }
          }

          .icon-icon_empty {
            display: none;
            cursor: pointer;
            font-size: 12px;
            color: #8f959e;
            &:hover {
              color: #3272fe;
            }
          }
          .arco-divider {
            margin: 0;
            height: 16px;
          }
          .arco-select {
            border: 0;
            .arco-select-view-suffix {
              display: none;
            }
          }
        }
      }

      .checkbox {
        margin-top: 12px;
        padding-left: 0;
        .arco-checkbox-icon{
          &:hover{
            border-color:#3272fe;
          }
          border-color:#8D95A0;
          width: 16px;
          height: 16px;
        }
      }

      .deadline-wrap {
        display: flex;
        align-items: center;
        height: 56px;
        padding: 0 16px;
        /* 分割线/常规 */
        border-top: 1px solid #dbe0e3;
        border-bottom: 1px solid #dbe0e3;
        .deadline-selectWrap {
          flex: 1;
          display: flex;
          align-items: center;
          &:hover {
            .icon-icon_close_med {
              visibility: visible;
            }
          }
          .arco-select {
            flex: 1;
            border: 0;
            border-radius: 6px;
            &:hover {
              background-color: rgba(31, 35, 41, 0.08);
              .arco-select-view-suffix {
                display: inline-flex;
              }
            }
            .arco-select-view-suffix {
              display: none;
            }
            .icon-icon_d_arrow {
              font-size: 10px;
              display: flex;
              color: #646a73;
            }
            .arco-select-view-opened .icon-icon_d_arrow {
              transform: rotateZ(180deg);
              display: block;
            }
          }
          .icon-icon_close_med {
            color: #646A73;
            visibility: hidden;
            cursor: pointer;
            padding: 4px;
            border-radius: 6px;
            font-size: 16px;
            margin-left: 4px;
            cursor: pointer;
            &:hover {
              background: rgba(31, 35, 41, 0.08);
            }
          }
        }
      }
      .clearAll {
        text-align: right;
        padding: 10px 16px;
        .arco-btn {
          color: #4e5969;
          padding: 0 4px;
          height: 28px;
        }
      }
    }
  }
}
.task-drawer {
  .arco-drawer {
    border-radius: 12px 12px 0px 0px;
  }
  .arco-drawer-body {
    padding: 0;
  }
}
.message-task-page {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  .content {
    padding: 0 20px;
    flex: 1 !important;
  }
  .footer {
    width: 100%;
    justify-content: center;
    .arco-btn {
      width: calc(100% - 40px);
      height: 36px;
    }
    .arco-btn-primary.arco-btn-disabled {
      background-color: #bbc0c3 !important;
    }
  }
}
</style>
<style scoped lang="scss">
.task-page-index {
  position: relative;
    .record-drag-upload-mask {
    width: 100%;
    height: calc(100% - 56px);
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    border-radius: 8px;
    background: rgba(238, 244, 255, 0.95);
    border: 1px dashed #126FFF;;
    .tips-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 120px;
        height: 120px;
        margin-bottom: 6px;
      }
      span {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        color: #86909C;
      }
    }
  }
  .content {
    height: 368px;
    overflow-y: auto;
   
    .textarea {
      border: 0;
      border-radius: 6px;
      // height: 22px;
      &:hover {
        // background: rgba(31, 35, 41, 0.08) !important;
      }
      :deep(.arco-textarea) {
        // height: 22px !important;
        min-height: 22px;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #3d3d3d;
      }
    }
    > ul {
      margin-top: 20px;
      > li {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        > .iconfont {
          font-size: 18px;
          color: #8f959e;
          padding-right: 12px;
        }
        .time-wrap {
          display: flex;
          gap: 8px;
          .arco-btn {
            height: 28px;
            padding: 0 4px;
            .iconfont {
              margin-right: 4px;
            }
          }
          .currenValueButton {
            &:hover {
              .icon-icon_close_med {
                display: inline-block;
              }
            }
            .arco-btn {
              background-color: transparent;
              &:hover {
                background-color: var(--color-secondary-hover);
              }
              .icon-icon_bell {
                margin-left: 6px;
                font-size: 14px;
              }
            }
            .icon-icon_close_med {
              color: #646A73;
              display: none;
              padding: 4px;
              border-radius: 6px;
              font-size: 12px;
              margin-left: 10px;
              cursor: pointer;
              &:hover {
                background: rgba(31, 35, 41, 0.08);
              }
            }
          }
          .icon-icon_time_today {
            color: rgb(50, 114, 254);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            span {
              position: absolute;
              font-size: 12px;
              transform: scale(0.6);
              bottom: 0.5px;
              font-weight: 600;
            }
          }
        }
      }
      .file-li {
        align-items: flex-start;
        margin-bottom: 0;

        > .iconfont {
          padding-top: 5px;
        }
        .file-wrap {
          width: calc(100% - 30px);
        }
        .addFile-tips {
          font-size: 12px;
          padding: 0 4px;
          line-height: 28px;
          color: #86909c;
          display: inline-block;
        }
        .task-cus-uploaded-file-list {
          width: 100%;
          margin-top: 4px;
          margin-bottom: 8px;
          li {
            width: 100%;
            // width: calc(100% - 36px)
          }
          .edit-file-item {
            width: 100%;
            height: 26px;
            line-height: 26px;
            border-radius: 6px;
            padding: 0 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 苹方-简;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #1f2329;
            cursor: pointer;
            transition: all 0.2s;
            margin-top: 0 !important;
            &:hover {
              background: #eceeee;
              .icon-cont {
                display: flex;
                width: 20px !important;
                height: 20px !important;
                // visibility: visible;
              }
            }
            .file-item-left {
              flex-shrink: 0;
              flex-grow: 0;
              // width: 100%;
              width: calc(100% - 36px);
              display: flex;
              align-items: center;
              .file-icon{
                width: 16px;
                height: 16px;
              }
              .file-name {
                display: block;
                width: 100%;
                // width: 500px;
                color: #333b44;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-left: 4px;
              }
            }

            span {
              display: flex;
              align-items: center;
            }
            .icon-cont {
              // visibility: hidden;
              display: none;
            }
            .iconfont {
              font-size: 14px;
              display: inline-block;
              transition: all 0.2s;
            }
            &:hover {
              .iconfont {
                cursor: pointer;
              }
            }
          }
        }
        .arco-upload-hide {
          display: inline-block;
        }
      }
    }
  }
}
.footer {
  display: flex;
  height: 56px;
  align-items: center;
  // padding: 0 24px;
  .left-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    .icon-icon_12 {
      cursor: pointer;
      padding: 4px;
      border-radius: 6px;
      font-size: 18px;
      // margin-left: 4px;
      color: #646a73;
      cursor: pointer;
      &:hover {
        background: rgba(31, 35, 41, 0.08);
      }
    }
    .addMembers {
      height: 28px;
      padding: 0 4px;
      background: transparent;
      color: #1f2329;
      &:hover {
        background: rgba(31, 35, 41, 0.08);
      }
      .iconfont {
        margin-right: 6px;
        color: #646a73;
        font-size: 18px;
      }
    }
  }
  .button-wrap {
    display: flex;
    gap: 12px;
    .arco-btn {
      // width: 80px;
    }
    .arco-btn-primary.arco-btn-disabled {
      background-color: #bbc0c3 !important;
    }
  }
}
.avatar-cont-wrap {
  display: flex;
  height: 28px;
  background-color: #f3f4f5;
  padding: 0px 8px 0 2px;
  border-radius: 40px;
  transform: none;
  &:hover {
    background-color: #dee1e3;
    .avatar-cont {
      border-color: #dee1e3;
    }
  }
  .avatar-cont:first-of-type {
    margin-left: -2px;
  }
  .avatar-cont {
    border: 2px solid rgb(245, 246, 247);
    margin-left: -6px;
    z-index: 1;
    border-radius: 50px;
    background: rgb(245, 246, 247);
    display: flex;
    .avatar-img {
      max-width: 24px;
      max-height: 24px;
      text-align: center;
      overflow: hidden;
      border-radius: 50%;
    }
  }
  span {
    font-family: 苹方-简;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    margin-left: 4px;
    color: #1f2329;
  }
}
</style>
