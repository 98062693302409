<template>
  <a-tooltip
    :position="position ?? 'top'"
    :popup-visible="visible"
    :disabled="isError"
    @popup-visible-change="handlePopupVisibleChanged"
    :content-class="contentClassName"
  >
    <div class="inherit" :class="className" @mouseenter="doReCalc">
      <!-- <span ref="domRef">{{ content }}</span> -->
      <span ref="domRef" v-html="transConttent"></span>
    </div>
    <template #content>
      <span v-html="transConttent" class="tooltips-content-text"></span>
    </template>
  </a-tooltip>
</template>

<script lang="ts" setup>
import { ref, computed, PropType} from 'vue'
import useEllipsis from '@/composables/useEllipsis'
const emits = defineEmits(['updateIsOverflow'])

const props = defineProps({  
  isError: {
    type: Boolean,
    default: false
  },
  content: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  className:{
    type: [String, Array] as PropType<string | string[]>, // 使用 TypeScript 的联合类型  
    default: () => '', // 或者你可以提供一个默认的数组，比如 []  
  },
  contentClassName: {
    type: String,
    default: ''
  },
  position: {
    type: String ,
    default: 'top'

  }
});  

const transConttent = computed(() => {
  if(props.content?.length) {
    let htmlText = props.content.replace(/\n/g, '<br>')
    return htmlText
  }
  return props.content
})

const visible = ref(false)
const domRef = ref()
const { isOverflow, doReCalc } = useEllipsis(domRef)

const tooltipDisabled = computed(() => {
  return props.disabled || !isOverflow.value
})

const handlePopupVisibleChanged = (v: boolean) => {
  if (v) {
    doReCalc()
    emits('updateIsOverflow', isOverflow.value)
    if (tooltipDisabled.value) return
  }

  visible.value = v
}
</script>

<style lang="scss" scoped>
.tooltips-content-text {
  padding: 0 0 0 5px;
  display: block;
  max-height: 300px;
  overflow: auto;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e8eb;
  }
}
.inherit {
  overflow: inherit;
  text-overflow: inherit;
  white-space: inherit;
  word-break: inherit;
}
</style>
